$main-font:'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
$main-font-size: 12px;
$main-bg-color: #088dba;

body {
  margin: 0;
  height: 100%;
  font-family: $main-font;
  font-size: $main-font-size;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
}

.img-center {
  margin: 0 auto;
}

.login-sm-logo-top {
  margin-top: 30px;
}

.sidebar-login {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $main-bg-color;
}

.topbar-login {
  background-color: $main-bg-color;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.login-sm-top {
  padding-top: 150px;
}

.lang {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.lang-menu>li>a {
  padding: 3px 10px;
}

.lang-btn {
  padding: 4px 8px;
  margin-bottom: 0;
  font-size: 13px;
  min-width: 130px;
}

.lang-menu {
  min-width: 130px;
  margin: 1px 0 0;
  font-size: 14px;
}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .topbar-login {
    padding-top: 30px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}